import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-visual" }
const _hoisted_2 = { class: "neon-card" }
const _hoisted_3 = { class: "neon-card-center" }
const _hoisted_4 = { class: "mb-32 mt-0 text-center" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "mb-24 mt-0 text-bold text-center" }
const _hoisted_7 = { class: "text-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "bg-color" }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, [
          _createElementVNode("img", {
            width: "161",
            height: "42",
            src: _ctx.state.clientIconUrl,
            alt: "Target and COMPANY logo"
          }, null, 8, _hoisted_5)
        ]),
        _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.state.consentTitleText), 1),
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.state.consentDisplayText), 1)
      ])
    ])
  ]))
}